/* eslint-disable no-debugger */
import UpgradeVersion from "@/components/UpgradeVersion";
import UserService from "~/service/userService";
export default {
  data() {
    return {
      versionInterval: null
    };
  },
  methods: {
    async checkVersion() {
      this.versionInterval = window.setInterval(async () => {
        try {
          var actualUrl = window.location.href;
          let remoteVersion = await UserService.checkRemoteVersion();
          if (
            remoteVersion &&
            remoteVersion.data &&
            actualUrl.indexOf(remoteVersion.data.lastVersion.version) === -1 &&
            this.versionCompare(
              global.config.version,
              remoteVersion.data.lastVersion.version
            ) == -1
          ) {
            console.log("Check App Version FOUND!");
            console.log("Check App Version actualUrl ", actualUrl);
            console.log(
              "Check App Version remoteVersion ",
              remoteVersion.data.lastVersion.version
            );
            this.stopInterval();
            let result = await this.openDialog(
              UpgradeVersion,
              {
                waitForResult: true,
                width: 500,
                version: remoteVersion.data
              },
              false
            );
            if (result) {
              var platform = "ios";

              // eslint-disable-next-line no-undef
              platform = device.platform.toLowerCase();
              var newUrl = remoteVersion.data.lastVersion.url.replace(
                "{platform}",
                platform
              );
              console.log("NEW URL APP :::: ", newUrl);
              window.location.href = newUrl;
            }
            return result;
          }
        } catch (err) {
          return;
        }
      }, global.config.checkVersionInterval);
    },
    versionCompare(v1, v2, options) {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split("."),
        v2parts = v2.split(".");

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
          return 1;
        }

        if (v1parts[i] == v2parts[i]) {
          continue;
        } else if (v1parts[i] > v2parts[i]) {
          return 1;
        } else {
          return -1;
        }
      }

      if (v1parts.length != v2parts.length) {
        return -1;
      }

      return 0;
    },
    stopInterval() {
      clearInterval(this.versionInterval);
    }
  }
};
