<template>
  <div class="footer-wrapper" @click="clicked">
    <!-- LINK DA CMSERVICE -->
    <div class="primary">
      <v-container fluid class="links px-2 px-sm-3 pa-md-9">
        <v-row class="newsletter align-center py-4 mb-md-9">
          <v-col cols="12" md="6">
            <h3 class="mb-2 font-weight-bold white--text">
              {{ $t("newsletter.title") }}
            </h3>
            <span class="white--text">
              {{ $t("newsletter.text") }}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <v-form
              ref="form"
              class="d-flex flex-column flex-md-row"
              v-on:submit.prevent
            >
              <v-text-field
                v-model="email"
                class="mr-md-4"
                v-on:keyup.enter="validate()"
                :placeholder="$t('newsletter.email')"
                :rules="emailRules"
                :error-messages="errors"
                type="email"
                autocomplete="off"
                outlined
                hide-details="auto"
              ></v-text-field>
              <v-btn
                x-large
                class="mt-5 mt-md-0"
                color="secondary"
                @click="validate()"
                >{{ $t("newsletter.cta") }}</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
        <v-row justify-lg="space-between">
          <v-col
            cols="12"
            md="2"
            lg="1"
            class="pt-4 pl-8 pb-0 pa-sm-0 d-block d-sm-none d-md-block"
          >
            <v-img
              src="/logo.svg"
              :max-width="isMobile ? 50 : 113"
              alt="Logo Eurospin"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
            v-for="item in links"
            :key="item.ID"
            class="justify-center align-center text-left text-sm-center text-md-left pt-3 pt-md-0 px-8 px-lg-0 mb-0"
          >
            <v-expansion-panels
              flat
              accordion
              :value="isMobile && item.title !== 'Contatti' ? 1 : 0"
              :disabled="!isMobile || item.title === 'Contatti'"
            >
              <v-expansion-panel class="primary">
                <v-expansion-panel-header
                  :v-ripple="false"
                  class="pa-0 text-left text-sm-center text-md-left"
                >
                  <div class="title text-uppercase white--text">
                    {{ item.name }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pb-0 pl-2 pl-sm-0">
                  <div class="d-flex flex-column" v-if="!item.classes">
                    <a
                      v-for="child in item.templateNavItems"
                      :key="child.ID"
                      :href="child.itemUrl"
                      class="white--text"
                      :class="child.class"
                      :target="child.target"
                      @click="clickLink"
                    >
                      {{ isSocialTitle(child) ? "" : child.name }}
                      <!-- FIX: see below -->
                      <span
                        v-if="isSocialTitle(child)"
                        class="social-title ml-1"
                        v-html="isSocialTitle(child)"
                      ></span>
                    </a>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container
      class="address d-flex flex-column justify-center align-center text-center"
    >
      <span>{{ $t("footer.copyright") }}</span>
      <i18n path="common.version" tag="span" class="text-caption mt-1">
        {{ version }}
      </i18n>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.footer-wrapper {
  width: 100%;
  background: $primary;
  .newsletter {
    border-bottom: 1px solid var(--v-primary-lighten1);
    :deep(.v-input__slot fieldset) {
      background-color: $white;
    }
  }
  .v-expansion-panel-header {
    min-height: 36px !important;
    :deep(.v-expansion-panel-header__icon) {
      .v-icon {
        font-size: 16px;
        color: $white !important;
        &:before {
          font-family: $eurospin-font-family;
          content: "\49";
        }
      }
    }
    .title {
      height: auto;
    }
  }
  .v-expansion-panel-header--active {
    min-height: 36px !important;
    :deep(.v-expansion-panel-header__icon) {
      .v-icon {
        transform: rotate(90deg) !important;
      }
    }
  }
  :deep(.v-expansion-panel-content__wrap) {
    padding: 0px !important;
    // @media #{map-get($display-breakpoints, 'xs-only')} {
    // }
    // @media #{map-get($display-breakpoints, 'sm-and-up')} {
    //   padding: 0px !important;
    // }
  }
}
.links {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    height: 35px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
      line-height: 28px;
    }
  }

  a {
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    &.socials {
      font-family: $body-font-family;
    }
    [class*=" icon-"] {
      font-family: $eurospin-font-family;
    }
    .social-title {
      font-family: $body-font-family !important;
      font-size: 14px;
      line-height: 28px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      line-height: 24px;
    }
  }

  .footer-contatti {
    font-weight: 600;
  }

  .numero-verde {
    font-family: $body-font-family !important;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    &.icon-phone:before {
      font-size: 20px;
      font-family: $eurospin-font-family;
    }
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      font-size: 18px;
    }
  }
}

.address {
  background: $primary;
  padding: 30px;
  border-top: 1px solid var(--v-primary-lighten1);
  span {
    font-size: 12px;
    line-height: 18px;
    color: $white;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 24px;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import { requiredValue, isEmail } from "~/validator/validationRules";
import login from "~/mixins/login";
export default {
  name: "Footer",
  props: ["links"],
  mixins: [clickHandler, login],
  data() {
    return {
      errors: [],
      emailRules: [requiredValue("*Campo obbligatorio"), isEmail()],
      email: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    isSocialTitle(child) {
      // used to set two different font-families on the same string
      // one for icon, one for text
      if (child.class && child.class.search("icon") > -1) {
        return child.name;
      }
      return "";
    },
    clickLink: function(event) {
      if (event.target.className.includes("iubenda-cs-preferences-link")) {
        // eslint-disable-next-line no-undef
        _iub.cs.api.openPreferences();
      }
    },
    validate() {
      // console.log("validate");

      let _this = this;

      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.valid) {
            _this.$router.push({
              name: "Communication",
              query: { email: _this.email }
            });
          }
        }
      }, 200);
    }
  }
};
</script>
