<template>
  <h1
    v-if="cart.totalItems < 1"
    class="primary--text text--lighten-1 text-center py-7"
  >
    {{ $t("cart.cartIsEmpty") }}
  </h1>
  <div v-else>
    <div v-if="isCheckout" class="mt-4">
      <div
        no-gutters
        align-content="center"
        class="d-flex flex-row pa-2"
        v-if="!hideAcceptSubstitutes"
      >
        <v-checkbox
          dense
          color="primary"
          hide-details
          v-model="acceptAlternatives"
          class="accept-alternatives mt-0 pt-0 mr-1"
        >
          <template v-slot:label>
            <span>{{ $t("cart.acceptAlternatives") }}</span>
          </template>
        </v-checkbox>
        <v-tooltip bottom max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              class="ma-0 ml-sm-2"
              v-bind="attrs"
              v-on="on"
            >
              $info
            </v-icon>
          </template>
          <span>{{ $t("cart.acceptAlternativesTooltip") }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown && full"
      class="table-header pa-4 pb-0 text-body-2 font-weight-bold justify-space-between"
    >
      <v-col cols="3" md="4" lg="4" class="d-flex justify-start">
        {{ $t("checkout.tableHeader.product") }}
      </v-col>

      <v-col cols="2" md="2" lg="2" xl="2" class="text-center">
        {{ $t("checkout.tableHeader.unitPrice") }}
      </v-col>
      <v-col cols="3" class="d-flex justify-center">
        {{ $t("checkout.tableHeader.quantity") }}
      </v-col>
      <v-col cols="3" class="d-flex justify-center text-center">
        {{ $t("checkout.tableHeader.totalPrice") }}
      </v-col>
    </v-row>
    <v-list
      id="cartItemList"
      flat
      subheader
      dense
      class="cart-item-list"
      :class="{ 'checkout-item-list': full }"
    >
      <!-- three-line -->

      <template v-if="!full">
        <v-subheader
          class="primary--text text--lighten-1 text-body-2 mx-5 d-flex justify-space-between mb-2"
        >
          <CartItemCount class="cart-item-count" :count="cart.totalItems" />
          <v-btn
            elevation="0"
            class="empty-cart-btn primary--text text--lighten-1 white"
            v-if="cart.cartItems.length > 0 && cart.totalItems > 0"
            @click="emptyCart(cart.cartItems)"
            >Svuota il carrello</v-btn
          >
        </v-subheader>
      </template>

      <v-list-item-group
        class="flex align-center"
        :class="{ 'cart-item-list-no-checkout': !full }"
      >
        <v-list-item
          v-for="cartItem in cart.cartItems"
          :key="cartItem.cartItemId"
          :to="{
            name: 'Product',
            params: { productSlug: cartItem.product.slug }
          }"
          class="px-0"
          :ripple="!isCheckout"
        >
          <CartItem
            :item="cartItem"
            :isCheckout="isCheckout"
            :full="full"
            :showDescrWeight="showDescrWeight"
            :hideAcceptSubstitutes="hideAcceptSubstitutes"
          />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.table-header {
  background: $white;
  color: $text-color;
  text-transform: none;
}
</style>
<script>
import CartItem from "./CartItem.vue";
import CartItemCount from "./CartItemCount.vue";

import { mapActions, mapState } from "vuex";

import reduce from "lodash/reduce";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: {
    full: { type: Boolean, default: false },
    showDescrWeight: { type: Boolean, default: true },
    hideAcceptSubstitutes: { type: Boolean, default: true },
    isCheckout: { type: Boolean, default: false }
  },
  data() {
    return {
      panel: [],
      showSectors: null,
      tempOrderComment: null,
      filteredItems: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (
            get(cartItem, "cartItemInfo.accept_alternatives", "true") == "false"
          ) {
            value = false;
          }
          return value;
        });
        reduce(
          this.cart.cartItems,
          function(value, cartItem) {
            return (value && cartItem.cartItemInfo.accept_alternatives) || true;
          },
          true
        );
        return value;
      },
      set(value) {
        // let cartItemsPromo = [];
        this.filteredItems = cloneDeep(this.cart.cartItems);
        this.cart.cartItems.forEach(cartItem => {
          if (cartItem.product.warehousePromo) {
            this.filteredItems = this.filteredItems.filter(c => {
              return c.itemId !== cartItem.itemId;
            });
          }
        });
        this.setCartItemInfo({
          items: this.filteredItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        this.tempOrderComment = value;
      }
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    })
  },
  mounted() {
    this.showSectors = global.config.showSectors;
    if (global.config.showSectors) {
      if (this.cart && this.cart.sectors) {
        this.panel = this.cart.sectors.map((k, i) => i);
      }
    }
  },
  watch: {
    cart() {
      if (global.config.showSectors) {
        if (this.cart && this.cart.sectors) {
          this.panel = this.cart.sectors.map((k, i) => i);
        }
      }
    }
  }
};
</script>
