<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    :hideEdit="true"
    icon="/img_layout/calendar.svg"
    :editable="editable"
    @edit="edit"
    :small="small"
    :isShopopop="isShopopop"
    />
  
</template>
<script>
import CartInfoCard from "./CartInfoCard";
import { mapState } from "vuex";
export default {
  name: "CartInfoTimeslotCard",
  props: {
    shippingAddress: { type: Object, required: true },
    timeslot: { type: Object, required: true },
    editable: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  components: { CartInfoCard },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    value() {
      if (this.timeslot && !this.timeslot.autoAssigned) {
        let date = this.$dayjs(this.timeslot.date);
        return (
          date.format("D MMMM") +
          " | " +
          this.$dayjs(this.timeslot.beginTime, ["h:mm:ss A", "H:mm"]).format(
            "HH:mm"
          ) +
          " - " +
          this.$dayjs(this.timeslot.endTime, ["h:mm:ss A", "H:mm"]).format(
            "HH:mm"
          )
        );
      }
      return this.$t("navbar.noTimeslot");
    },
    text() {
      return this.$t(
        "navbar.address." +
          this.shippingAddress.addressTypeId +
          ".timeslotButtonTitle"
      );
    },
    isShopopop() {
      return this.cart.shippingAddress.zone.zoneGroupId == 5;
    }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
