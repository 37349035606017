const routes = [
  {
    path: "/contatti",
    name: "ContactForm",
    component: () => import("@/views/forms/ContactForm.vue"),
    meta: {
      slug: "contatti"
    }
  },
  {
    path: "/ricevi-le-comunicazioni",
    name: "Communication",
    component: () => import("@/views/forms/Communication.vue"),
    meta: {
      slug: "ricevi-le-comunicazioni"
    }
  }
];

export default routes;
