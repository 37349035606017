<template>
  <v-app-bar app color="primary" dark clipped-left :class="classCordova">
    <!-- barra navigazione desktop -->
    <v-container fluid class="navbar-container d-flex flex-column px-0 pb-0">
      <v-row no-gutters class="nav px-2 px-sm-4">
        <v-col
          cols="4"
          sm="1"
          class="d-flex d-sm-none justify-start align-center p-sm-auto"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
            :to="{ path: $routerHistory.previous().path }"
            elevation="0"
            x-small
            class="back-button"
            aria-label="Torna Indietro"
          >
            <v-icon large elevation="0">$chevronLeft</v-icon>
          </v-btn>
          <v-app-bar-nav-icon
            @click.prevent.stop="drawerLeft = !drawerLeft"
            class="drawer-right"
          >
            <template v-slot:label>
              <v-btn
                color="secondary"
                icon
                small
                aria-label="Apri menu categorie"
              >
                <v-icon medium>$menu</v-icon>
              </v-btn>
            </template>
          </v-app-bar-nav-icon>
        </v-col>

        <v-col
          cols="4"
          sm="1"
          class="order-1 d-flex justify-center align-center"
        >
          <router-link to="/" class="d-flex my-1">
            <img
              ref="logo"
              :src="logoImg"
              alt="Logo Eurospin"
              class="header-logo"
              v-scroll="onScroll"
            />
          </router-link>
        </v-col>

        <!-- CAMPO DI RICERCA (non visibile su phone, spostato nella BottomNavBar) -->
        <v-col
          cols="12"
          sm="7"
          :md="isAuthenticated ? 4 : 8"
          :lg="isAuthenticated ? 5 : 7"
          class="search pa-0 pl-sm-8 d-flex flex-row align-center order-last order-sm-2 my-2 my-sm-0"
        >
          <SearchInput />
        </v-col>
        <v-col
          cols="4"
          :md="isAuthenticated ? 7 : 3"
          :lg="isAuthenticated ? 6 : 4"
          class="d-flex justify-space-between pr-1 order-3"
        >
          <!-- NON AUTENTICATO -->
          <div class="not-auth profile-nav justify-end" v-if="!isAuthenticated">
            <v-btn @click.stop="handleLogin()" icon color="white">
              <div class="d-flex flex-column justify-center align-center">
                <v-icon :large="$vuetify.breakpoint.smAndUp">$profile</v-icon>
              </div>
            </v-btn>
            <v-btn icon color="white" @click.stop="handleLogin()">
              <v-icon :large="$vuetify.breakpoint.smAndUp">$cart</v-icon>
            </v-btn>
          </div>

          <!-- AUTENTICATO -->
          <div
            class="cart-navbar profile-nav justify-end justify-sm-space-between"
            v-else
          >
            <!-- CONSEGNA -->
            <CartInfoButton class="pl-5" />
            <!-- <v-spacer /> -->
            <!-- TIMESLOT (mobile) -->
            <v-btn
              aria-label="Seleziona fascia oraria"
              class="d-flex d-md-none"
              icon
              color="white"
              @click.stop="openTimeSlotSelector"
              ><v-icon>$clock</v-icon></v-btn
            >
            <!-- PROFILO -->
            <div class="d-flex flex-row align-center justify-space-between">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on.stop="on"
                    icon
                    color="white"
                    class="mx-0 mx-sm-2"
                  >
                    <v-icon :large="$vuetify.breakpoint.smAndUp"
                      >$profile</v-icon
                    >
                  </v-btn>
                </template>
                <MenuNavigator class="account-menu" />
              </v-menu>

              <!-- LISTE-->
              <v-btn
                aria-label="Vai alle liste dei preferiti"
                class="profile-nav d-none d-sm-flex mx-2"
                to="/profile/lists"
                icon
                color="white"
              >
                <div class="d-flex flex-column justify-center align-center">
                  <v-icon :large="$vuetify.breakpoint.smAndUp">$heart</v-icon>
                </div>
              </v-btn>

              <!-- CARRELLO -->
              <v-badge
                :content="totalItems"
                :value="totalItems > 0"
                :key="totalItems"
                transition="bounce"
                color="secondary"
                overlap
              >
                <v-btn
                  text
                  color="white"
                  width="auto"
                  @click.stop="openCart()"
                  class="pa-0"
                >
                  <div class="d-flex flex-row justify-center align-center">
                    <v-icon :large="$vuetify.breakpoint.smAndUp">$cart</v-icon>
                    <div class="ml-2 d-none d-md-flex flex-column">
                      <div style="font-weight: 400;text-transform:none;">
                        Carrello
                      </div>
                      <div class="label">
                        <div>{{ $n(cart.totalPrice, "currency") }}</div>
                      </div>
                    </div>
                  </div>
                </v-btn>
              </v-badge>
            </div>
          </div>
        </v-col>
      </v-row>
      <div
        class="links d-none d-sm-flex links align-center px-2 py-1"
        @click="clicked"
      >
        <v-hover v-slot="{ hover }" class="mr-4">
          <v-btn
            class="drawer"
            :class="!drawerLeft && !hover ? 'secondary' : 'primary lighten-1'"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon medium class="mt-1 mr-0 mr-lg-2">$menu</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp" class="products-btn">
              {{ $t("navbar.products") }}
            </span>
          </v-btn>
        </v-hover>
        <div class="lower-links d-flex flex-row align-center">
          <div v-for="(link, index) in links" v-bind:key="index">
            <a
              v-if="link.templateNavItems.length == 0"
              class="px-2 px-md-3 text-decoration-none"
              :class="link.classes"
              :href="link.itemUrl"
            >
              {{ link.name }}
            </a>
            <v-menu
              v-else
              bottom
              offset-y
              tile
              nudge-bottom="12"
              content-class="children-links-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on.stop="on"
                  class="px-3"
                  :class="link.class"
                  @click="clicked"
                >
                  <span class="mr-1">{{ link.name }}</span>
                  <v-icon small color="grey darken-2">$chevronDownLight</v-icon>
                </a>
              </template>
              <a
                v-for="(templateNavItems, idx2) in link.templateNavItems"
                :key="idx2"
                class="nav-menu-link px-4 py-2 white grey--text text--darken-2 text-decoration-none"
                :class="templateNavItems.classes"
                :href="templateNavItems.itemUrl"
                @click="clicked"
              >
                {{ templateNavItems.name }}
              </a>
            </v-menu>
          </div>
        </div>
        <v-spacer />
        <v-btn
          v-if="showCheckoutButton"
          elevation="0"
          class="white primary--text text--lighten-1 border-black"
          :to="{ name: 'Checkout' }"
        >
          Vai alla cassa
        </v-btn>
      </div>
    </v-container>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>

<style scoped lang="scss">
body.small {
  .v-app-bar {
    .header-logo {
      height: 48px;
    }
  }
  .navbar-container {
    padding: 0px;
    .nav {
      padding: 6px 0px;
    }
  }
}
.v-app-bar {
  height: auto !important;
  .links {
    background-color: $white;
    a,
    .menu-btn {
      color: $text-color;
      font-size: 13px;
    }
    .menu-btn {
      color: $text-color;
      font-size: 12px;
      text-decoration: none;
      text-transform: none;
      padding: 0;
      ::-v-deep .v-btn__content {
        font-weight: 400 !important;
      }
    }

    .bold {
      font-weight: 600;
    }

    .bio {
      color: $category-bio;
    }
    .senza-glutine {
      color: $category-senza-glutine;
    }
    .senza-lattosio {
      color: $category-senza-lattosio;
    }

    .products-btn {
      font-size: 14px;
    }
  }

  .cart-navbar,
  .not-auth {
    width: 100%;
  }
  .nav {
    height: 100%;
    width: 100%;
    background-color: $primary;

    .header-logo {
      height: 100px;
      transition: height 0.3s ease-in-out;
      &.small {
        padding-top: 2px;
        height: 48px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        height: 48px;
        max-width: 150px;
      }
      &.small {
        height: 50px;
      }
    }

    .drawer {
      min-width: 100px !important;
      width: 100px;
      height: 80px;
      margin-top: -12px;
      margin-bottom: -12px;
      border-radius: 0;
      font-size: 16px !important;
      i {
        color: $white !important;
        font-size: 30px;
      }
    }

    .search {
      .v-btn {
        .label {
          line-height: 14px;
          font-size: 12px;
          color: var(--v-primary-lighten1);
          width: 60px;
          span {
            overflow-x: auto;
            white-space: break-spaces;
          }
        }
      }
    }
  }
  .border-black {
    border: 2px solid $black !important;
    font-size: 15px !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 69px !important;
    .v-btn {
      .label {
        font-size: 11px !important;
        font-weight: bold;
      }
    }
  }

  .v-progress-linear {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      bottom: -4px !important;
    }
  }
}

.lower-links {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .prev {
    position: fixed;
    left: 0;
    bottom: 15px;
    &::after {
      border-radius: $border-radius-root;
    }
  }
  .next {
    position: fixed;
    right: 0;
    bottom: 15px;
    &::after {
      border-radius: $border-radius-root;
    }
  }
}

.children-links-menu {
  background: $white !important;
  font-size: 12px !important;
  min-width: 125px !important;
  padding: 5px;
  display: flex;
  flex-direction: column;
  a {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color !important;
    }
  }
  a:hover {
    background-color: var(--v-background-base) !important;
  }
}

:deep(.cart-info-row) {
  max-width: 60%;
  .service-icon-btn {
    @media screen and (max-width: 1150px) {
      display: none;
    }
  }
  .right-col {
    max-width: 90%;
    .v-btn {
      width: 100%;
      .v-btn__content {
        width: 100% !important;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    @media screen and (min-width: 960px) and (max-width: 1100px) {
      max-width: 100%;
    }
  }
}
</style>

<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
import CartInfoButton from "@/components/categoryBlock/CartInfoButton.vue";

import login from "~/mixins/login";
import clickHandler from "~/mixins/clickHandler";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navbar",
  props: ["links", "user"],
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    MenuNavigator,
    CartInfoButton
  },
  data() {
    return {
      isMobile: false,
      logoImg: null
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    onScroll() {
      if (!this.$vuetify.breakpoint.xs) {
        if (window.scrollY > 55 && !document.body.classList.contains("small")) {
          document.body.classList.add("small");
          this.logoImg = "/logo-no-payoff.svg";
        } else if (
          window.scrollY < 55 &&
          document.body.classList.contains("small")
        ) {
          this.logoImg = "/logo.svg";
          document.body.classList.remove("small");
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    swipeLeft() {},
    swipeRight() {}
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({ showOverlay: "app/showOverlay" }),
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    showCheckoutButton() {
      return (
        this.isAuthenticated &&
        this.cart.totalItems > 0 &&
        this.$vuetify.breakpoint.mdAndUp
      );
    },
    scrollArrows() {
      return true;
    }
  },
  created() {
    this.logoImg = this.$vuetify.breakpoint.xs
      ? "/logo-no-payoff.svg"
      : "/logo.svg";
  }
};
</script>
